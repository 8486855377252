/**
 * @file   : PageMeta.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 2/1/2019, 12:17:37 PM
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'

import { isEnvServer } from '../../../external/utilities/GeneralServerUtils'
import {
  isEmpty,
  isUndefined,
  isValue,
} from '../../../external/utilities/GeneralUtils'

const disableContractCodes = ['CT']
const disablePagesIds = [
  'PAGE_CART_VIEW',
  'PAGE_CART_CHECKOUT',
  'PAGE_CART_CONFIRMATION',
  'PAGE_SITE_DETAILS',
]

class PageMeta extends Component {
  componentDidMount() {
    this.setTargeting()
  }

  addDataElement(name, value, metaString) {
    let separator = ', '
    if (metaString.endsWith('{"tags": [')) {
      separator = ''
    }

    return `${separator}"${name}-${value}"`
  }

  getDisableStickyFooterScript() {
    const { freestar } = isEnvServer() ? {} : window
    const { pageMetaData = {} } = this.props

    if (!freestar) {
      return
    }

    const disableStickyFooter =
      disablePagesIds.includes(pageMetaData.pageId) ||
      disableContractCodes.includes(pageMetaData.contractCode)

    freestar.queue = []

    freestar?.queue?.push(() =>
      disableStickyFooter
        ? freestar.deleteStickyFooter()
        : freestar.newStickyFooter(),
    )
  }

  getJSMetaContent() {
    const { pageMetaData } = this.props
    let metaString = 'var special_ops = {"tags": ['

    if (!isUndefined(pageMetaData.pageId) && !isEmpty(pageMetaData.pageId)) {
      metaString += this.addDataElement('Page', pageMetaData.pageId, metaString)
    }

    if (
      !isUndefined(pageMetaData.contractCode) &&
      !isEmpty(pageMetaData.contractCode)
    ) {
      metaString += this.addDataElement(
        'ContrCd',
        pageMetaData.contractCode,
        metaString,
      )
    }

    if (isValue(pageMetaData.facilityId)) {
      metaString += this.addDataElement(
        'FacilityID',
        pageMetaData.facilityId,
        metaString,
      )
    }

    if (!isUndefined(pageMetaData.env) && !isEmpty(pageMetaData.env)) {
      metaString += this.addDataElement(
        'Environment',
        pageMetaData.env,
        metaString,
      )
    }

    metaString += ']};'

    return metaString
  }

  setTargeting() {
    const { pageMetaData = {} } = this.props

    const { freestar, googletag } = isEnvServer() ? {} : window

    if (!freestar || !googletag) {
      return
    }

    freestar.queue.push(() => {
      const { pageId, contractCode, facilityId, env } = pageMetaData

      if (pageId) {
        googletag?.pubads?.().setTargeting('pageId', [pageId])
      }

      if (env) {
        googletag?.pubads?.().setTargeting('env', [env])
      }

      const keywords = []

      if (contractCode) {
        keywords.push(contractCode)
      }

      if (facilityId) {
        keywords.push(facilityId)
      }

      if (keywords.length) {
        googletag?.pubads?.().setTargeting('keywords', keywords)
      }
    })
  }

  render() {
    // const stickyFoooterScript = this.getDisableStickyFooterScript()
    this.getDisableStickyFooterScript()

    return (
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: this.getJSMetaContent(),
          },
          // ...(stickyFoooterScript
          //   ? [
          //       {
          //         type: 'text/javascript',
          //         innerHTML: stickyFoooterScript,
          //       },
          //     ]
          //   : []),
        ]}
      />
    )
  }

  static propTypes = { pageMetaData: PropTypes.object.isRequired }
}

export default PageMeta
